import React, { useState } from 'react';

const SliderSearchComponent = (props) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSliderChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setSliderValue(newValue);
    props.setSearchTerm(getSentimentValue(newValue)); // Set the search term to the sentiment value of the slider
  };

  const getSentimentValue = (value) => {
    switch (true) {
      case value >= -1.0 && value < -0.8:
        return 'Very Negative';
      case value >= -0.8 && value < -0.6:
        return 'Negative';
      case value >= -0.6 && value < -0.4:
        return 'Moderately Negative';
      case value >= -0.4 && value < -0.2:
        return 'Slightly Negative';
      case value >= -0.2 && value < -0.1:
        return 'Neutral with a Hint of Negativity';
      case value >= -0.1 && value < 0.1:
        return 'Neutral';
      case value >= 0.1 && value < 0.2:
        return 'Neutral with a Hint of Positivity';
      case value >= 0.2 && value < 0.4:
        return 'Slightly Positive';
      case value >= 0.4 && value < 0.6:
        return 'Moderately Positive';
      case value >= 0.6 && value < 0.8:
        return 'Positive';
      case value >= 0.8 && value <= 1.0:
        return 'Very Positive';
      default:
        return 'Out of Range';
    }
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={-1}
        max={1}
        step={0.1}
        value={sliderValue}
        onChange={handleSliderChange}
      />
    </div>
  );
}

export default SliderSearchComponent;
