import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import SliderSearchComponent from './component/slider/SliderSearchComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faLeaf, faMusic,faCogs } from '@fortawesome/free-solid-svg-icons'
import {faFaceLaughBeam} from '@fortawesome/free-solid-svg-icons';
import thumbnailImage from './images/newsbox.png'; // Replace 'your_image.jpg' with the actual image file name

function App() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('electric vehicles');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sliderValue, setSliderValue] = useState(10);
  const [count, setCount] = useState(0);
  const [catData, setCatData] = useState([]);

  useEffect(() => {
    axios.get('/api/data')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  useEffect(() => {
    axios.get('/api/data/categories')
      .then(response => {
        setCatData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  function getPaginationRange(totalRows, currentPage, rowsPerPage) {
    const endIndex = currentPage * rowsPerPage;
    const startIndex = endIndex - rowsPerPage;
    return {
      startIndex: startIndex,
      endIndex: Math.min(endIndex, totalRows)
    };
  }

  function logClick1(url, title) {
    fetch('http://localhost:5000/log-click', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: 'Example Article',
        url: 'https://www.example.com/article'
      })
    });
  }

  function logClick(id, title, url) {
    fetch('http://localhost:5000/log-click', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, title, url })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to log click');
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const filteredData = data.filter(row =>
    row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.category.toLowerCase().includes(searchTerm.toLowerCase())||
    row.senti_label.toLowerCase() === searchTerm.toLowerCase()
  );

  //const filteredData = data.filter(row => row.title.toLowerCase().includes(searchTerm.toLowerCase()));
  const paginationRange = getPaginationRange(filteredData.length, currentPage, rowsPerPage);

  function getSentiClass(sentiLabel) {
    switch(sentiLabel) {
      case 'Very Negative':
        return 'senti-very-negative';
      case 'Negative':
        return 'senti-negative';
      case 'Moderately Negative':
        return 'senti-moderately-negative';
      case 'Slightly Negative':
        return 'senti-slightly-negative';
      case 'Neutral with a Hint of Negativity':
        return 'senti-neutral-with-hint-of-negativity';
      case 'Neutral':
        return 'senti-neutral';
      case 'Neutral with a Hint of Positivity':
        return 'senti-neutral-with-hint-of-positivity';
      case 'Slightly Positive':
        return 'senti-slightly-positive';
      case 'Moderately Positive':
        return 'senti-moderately-positive';
      case 'Positive':
        return 'senti-positive';
      case 'Very Positive':
        return 'senti-very-positive';
      default:
        return '';
    }
}

function capitalizeFirstLetter(str) {
  return str.split("|").map(subStr =>
    subStr.trim().split(" ").map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(" ")
  ).join(" | ");
}

  return (

    <div className="App">

<div className="headbox bg_image bg_image1"> 
    <FontAwesomeIcon icon={faNewspaper} size="3x" />&nbsp;
    <h1 style={{fontSize: '2em'}}>FeelBox</h1>
</div>

      <div class="other-sites">
    <a href="https://evland.us/" target="_blank" > <FontAwesomeIcon icon={faLeaf} />  EVLand.us&nbsp;&nbsp; | &nbsp;&nbsp; </a>
    <a href="https://songs.chummar.us" target="_blank" > <FontAwesomeIcon icon={faMusic} />  Raja Ragam</a>
</div>
      
      <div className="search-container">

      
        <input type="text" id="search" value={searchTerm} onChange={event => setSearchTerm(event.target.value)} />
        <i class="fa fa-search search-icon"></i> &nbsp;&nbsp;&nbsp;
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}><FontAwesomeIcon icon={faBackward} /></button>
          <span> &nbsp;  {currentPage}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={paginationRange.endIndex === filteredData.length}><FontAwesomeIcon icon={faForward} /></button>
      </div>
      <div className="slider-container">
      <SliderSearchComponent setSearchTerm={setSearchTerm} />
    </div>

            
         <p></p>
      <div className="pages">


      </div>
     
      <div className="categories">
        {catData.length > 0 && (
          <div>
            {catData.map((item, index) => (
              <React.Fragment key={index}>
                <a className="link" href="#" onClick={() => {setSearchTerm(item.category);setCurrentPage(1);}}>
                {capitalizeFirstLetter(item.category)}
                </a>
                {index !== catData.length - 1 && " | "}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <p></p>
      <table className="table">
    
        <tbody>
          {filteredData.slice(paginationRange.startIndex, paginationRange.endIndex).map((row, index) => (
            <tr key={row.id} data-category={row.category}>
            
              <td className="right-align">
                <div className="title">{row.title}</div>
                <p />
                <div className="description">{row.description}..<a href={row.url} target="_blank" rel="noopener noreferrer" onClick={() => logClick(row.id, row.title, row.url)}>
                  Read More
                </a></div>
                <p />
                <div >
                  <span className="published-ner">{row.ner}</span>
                </div>
                <div className="published">
                  {row.source_name}&nbsp;| {row.author}&nbsp;|&nbsp;
                  {new Date(row.published_at).toLocaleDateString('en', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                  })}
                  &nbsp;|&nbsp;<span className="published-cat"> {capitalizeFirstLetter(row.category)}</span>&nbsp;|&nbsp;<span className={`published-bold ${getSentiClass(row.senti_label)}`}> {row.senti_label} </span>
                  <br />
                  <div >
                  <span className="published-ner">{row.ner_full}</span>
                </div>
                <br/>
                  <div>
                    Share:&nbsp;
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(row.url)}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    &nbsp;|&nbsp;
                    <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(row.url)}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    &nbsp;|&nbsp;
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(row.url)}`} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    &nbsp;|&nbsp;
                    <a href={`mailto:?subject=Check%20out%20this%20link&body=${encodeURIComponent(row.url)}`}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                  </div>
                </div>
              </td>
              <td>
                <a href={row.url_to_image} target="_blank" rel="noopener noreferrer" onClick={() => logClick(row.id, row.title, row.url_to_image)}>
                  <img src={row.url_to_image} alt={row.title} style={{ width: "150px", height: "100px" }} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div class="social">
        <a class='facebook' href="https://www.facebook.com/pages/NewsBox/294716343884186" target="_blank"></a>
        <a class='twitter' href="http://twitter.com/#!/newsbox" target="_blank"></a>
        <a class='linkedin' href="http://www.linkedin.com/company/2435822?goback=.fcs_GLHD_newsbox" target="_blank"></a>
        <a class='rss' href="https://prsafe.com/publish-feeds/" target="_blank"></a>

      </div>
      <div className="footer bg_image">
      <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}><FontAwesomeIcon icon={faBackward} /></button>
          <span> &nbsp;  {currentPage}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={paginationRange.endIndex === filteredData.length}><FontAwesomeIcon icon={faForward} /></button>
      </div>
     

      <div class="other-sites">
    <a href="https://evland.us/" target="_blank" > <FontAwesomeIcon icon={faLeaf} />  EVLand.us&nbsp;&nbsp; | &nbsp;&nbsp; </a>
    <a href="https://songs.chummar.us" target="_blank" > <FontAwesomeIcon icon={faMusic} />  Raja Ragam</a>
    
</div>
<div className="footer">
<h4><FontAwesomeIcon icon={faCogs} />&nbsp;Mage Theory&nbsp;</h4>
        <p> | evland.us@gmail.com</p><br></br>
        
  
      </div>

    </div>

  );
}

export default App;
